export enum ImagePaths {
    pendingIcon = '../../assets/imgs/pending.svg',
    addPencilIcon = '../../assets/imgs/ajout-pencil.svg',
    detailsIcon = '../../assets/imgs/details.svg',
    checkIcon = '../../assets/imgs/check.png',
    checkList = '../../assets/imgs/check_list.svg',
    pdf = '../../assets/imgs/pdf.svg',
    invalidCraIcon = '../../assets/imgs/invalidCra.svg',
    validCraIcon = '../../assets/imgs/validCra.svg',
    valid = '../../assets/icons/valid.svg',
    export = '../../../../assets/icons/export.svg',
    craValid = '../../../../assets/icons/CraValid.svg',
    exportCRM = '/assets/imgs/Export.svg',
    sendEmail = "/assets/imgs/resmailbtn.svg",
    calendar = "/assets/imgs/calendar.svg",
    editIcon = "/assets/imgs/pencil.svg",
    defaultAvatar = "/assets/imgs/admin.svg",
    cinFront = '/assets/imgs/frontcin.png',
    cinBack = '/assets/imgs/backcin.png',
    calendar2='assets/imgs/leaveStatistics.svg',
    export2='/assets/imgs/exportHistory.svg',
   logoWIND ='/assets/imgs/Logowind.svg',

    validPointage = '/assets/imgs/valid-pointage.png',
    clockAlert = '/assets/imgs/clockAlert.png',
    pointageIcon = '/assets/imgs/pointageIcon.png',
    breakIcon = '/assets/imgs/breakIcon.png',
    lastTimePointage = '/assets/imgs/lastTimePointage.png',
    dateIcon = '/assets/imgs/dateIcon.png',
    statusIcon = '/assets/imgs/statusIcon.png',
    rttIcon = '/assets/imgs/rttIcon.png',
    break = '/assets/imgs/break-icon.png',
    statistics= '/assets/imgs/statistics.svg',
    addAmicale = '/assets/imgs/add-amicale.svg',
    percent = '/assets/imgs/percent.svg',
    logoAmicaleImage = '/assets/imgs/logo-amical-image.svg',
    fileLogo = '/assets/imgs/file-logo.svg',
    orSeparator = '/assets/imgs/or-separator.svg',
    prendCharge = '/assets/imgs/prend_charge.svg',
    datePicker = '/assets/imgs/date-picker.svg',
    logoValidAmicale = '/assets/imgs/logoValidAmicale.svg',
    pieceJointe = '/assets/imgs/piece-jointe.svg',
    approverAmicale = '/assets/imgs/approve.svg',
    validAmicale = '/assets/imgs/valid-amicale.svg',
    remiseCash = '/assets/imgs/remise-cash.svg',
    detailAmicale = '/assets/imgs/detail-amical.svg',
}
