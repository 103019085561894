import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

import { NotificationsService } from './notifications.service';
import { WEBSOCKET_ENDPOINT, WEBSOCKET_NOTIFY_TOPIC } from './constants/base-url.constants';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  stompClient: any;

  constructor(private notificationService: NotificationsService) { }

  connect(): void {

    const ws = new SockJS(WEBSOCKET_ENDPOINT);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect({}, function(frame) {
         let useruuid = localStorage.getItem('useruuid');
        _this.stompClient.subscribe(WEBSOCKET_NOTIFY_TOPIC +useruuid, function(sdkEvent) {
            _this.onMessageReceived(sdkEvent);
        });
    }, this.errorCallBack);
}


  disconnect(): void {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

   // on error, schedule a reconnection attempt
   errorCallBack(error) {
    setTimeout(() => {
        this.connect();
    }, 5000);
}
  onMessageReceived(message) {
   // Emits the event.
    this.notificationService.notificationMessage.emit(JSON.parse(message.body));
  }
}