import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class SystemGuard implements CanActivate {

  constructor(public authService: AuthService){
  }

  canActivate(){
    if(this.authService.isSystem())
      return true
    else
      return false;
  }

}
