import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { WebsocketService } from './websocket.service';
import {AvailableLangs, TranslocoService} from "@ngneat/transloco";
import {Router} from "@angular/router";
import {DATE_FORMAT_V4} from "../../../shared/constants/date";

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: any[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    activeLang: String;
    baseUrltoAddLead = 'admin/crm/kounouz-crm/suivi-leads/add-lead';


    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private websocketService :WebsocketService,
        private  notificationService : NotificationsService,
        private _translocoService: TranslocoService,
        private router: Router

    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.activeLang = this._translocoService.getActiveLang();
        this._translocoService.langChanges$.subscribe((activeLang) => {
            this.activeLang = activeLang;
            this._updateNotificationsLanguage();
        });

        this.connect();
        let uuid =  localStorage.getItem('useruuid')
        this._notificationsService.getAll(uuid)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: any) => {
                // Load the notifications
                this.notifications = notifications;

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    private _updateNotificationsLanguage(): void {
        this._changeDetectorRef.markForCheck();
    }

    connect(): void {
        this.websocketService.connect();
        this.notificationService.notificationMessage.subscribe((data) => {
            let uuid =  localStorage.getItem('useruuid')
           if ( uuid === data.receiver){
            this.notifications.push(data);
            this._calculateUnreadCount();
            this._changeDetectorRef.markForCheck();
           }
        });
      }

      disconnect(): void {
        this.websocketService.disconnect();
      }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void
    {
        // Return if the notifications panel or its origin is not defined
        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void
    {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe({
           next : (res:any)=>{
                this.notifications = res
                this._calculateUnreadCount();
                this._changeDetectorRef.markForCheck();
            }
        }

        );

    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: any): void
    {

        // Update the notification
        this._notificationsService.update(notification.uuid , notification.status).subscribe({
            next:(res:any)=>{
                this._calculateUnreadCount()
                this._changeDetectorRef.markForCheck();
            }
        });

    }

    /**
     * Delete the given notification
     */
    delete(notification: any): void
    {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                                  .withLockedPosition(true)
                                  .withPush(true)
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.notifications && this.notifications.length )
        {
            count = this.notifications.filter(notification => notification.status == 'UNSEEN').length;
        }

        this.unreadCount = count;
    }

    navigateToLead(notification: any): void {
        if (notification.status !== 'SEEN') {
            this.toggleRead(notification);
        }
        this.closePanel();
        if (notification.type === 'LEAD_NOTIFICATION') {
            const leadData = {
                uuid: notification.data.uuid,
                isEditMode: true
            };
            const encodedLeadData = encodeURIComponent(JSON.stringify(leadData));

            this.router.navigate([this.baseUrltoAddLead], {
                queryParams: { lead: encodedLeadData }
            });
        }
    }

    protected readonly DATE_FORMAT_V4 = DATE_FORMAT_V4;
}
