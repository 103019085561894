import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Material Form Controls
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Material Navigation
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
// Material Layout
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
// Material Buttons & Indicators
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatNativeDateModule, MatOptionModule, MatRippleModule} from '@angular/material/core';
// Material Popups & Modals
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
// Material Data tables
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {MatLuxonDateModule} from "@angular/material-luxon-adapter";
import {FuseHighlightModule} from "../../@fuse/components/highlight";
import {MaterialFileInputModule} from "ngx-material-file-input";
import {CdkScrollableModule} from "@angular/cdk/scrolling";
import {QuillModule} from "ngx-quill";
import {NgxColorsModule} from "ngx-colors";
import {AppModule} from "../app.module";
import {NgApexchartsModule} from "ng-apexcharts";
import { StatusInvoicePipe } from './pipes/status-invoice.pipe';
import { DashCaisseModule } from 'app/components/admin/dash-caisse/caisse.module';
import {NgxOrgChartModule} from "ngx-org-chart";
import {ImageCropperModule} from "ngx-image-cropper";
import {TranslocoModule} from "@ngneat/transloco";
import {NgxDropzoneModule} from "ngx-dropzone";
import {PinchZoomModule} from "@meddv/ngx-pinch-zoom";
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from "@angular-material-components/datetime-picker";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {TranslateMonthPipe} from "../../@fuse/pipes/find-by-key/pipeTransform";
import { DayNameThreeLettersPipe } from './pipes/day-name-three-letters.pipe';
import {DayNameSingleLetterPipe} from "./pipes/day-name-single-letter.pipe";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatCardModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatListModule,
        MatStepperModule,
        MatTabsModule,
        MatTreeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatBadgeModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatRippleModule,
        MatBottomSheetModule,
        MatDialogModule,
        MatSnackBarModule,
        NgxOrgChartModule,
        ImageCropperModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        CdkScrollableModule,
        QuillModule,
        NgxColorsModule,
        NgApexchartsModule,
        TranslocoModule,
        MatIconModule,
        MatTabsModule,
        NgxDropzoneModule,
        PinchZoomModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatOptionModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        NgxOrgChartModule,
        ImageCropperModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatCardModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatListModule,
        MatStepperModule,
        MatTabsModule,
        MatTreeModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatBadgeModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatRippleModule,
        MatBottomSheetModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatLuxonDateModule,
        FuseHighlightModule,
        MaterialFileInputModule,
        CdkScrollableModule,
        QuillModule,
        NgxColorsModule,
        NgApexchartsModule,
        StatusInvoicePipe,
        TranslocoModule,
        NgxDropzoneModule,
        PinchZoomModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatOptionModule,
        TranslateMonthPipe
    ],
    declarations: [

        StatusInvoicePipe,
        TranslateMonthPipe,
        DayNameSingleLetterPipe,
        DayNameThreeLettersPipe

    ]
})
export class SharedModule
{
}
