import { Injectable } from "@angular/core";
import {environment} from "../../../../../environments/environment";
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';

@Injectable({
    providedIn: 'root'
  })
export class StompService {
    
    baseUrl = environment.ticketUrl;
    //socket = new SockJS(`${this.baseUrl}/sba-websocket`);
    socket = new SockJS(`http://198.244.164.117:8762/wind-ticket-management/sba-websocket`);
    stompClient = Stomp.over(this.socket);

    subscribe(topic: string, callback:any):void {
        
        const connected: boolean = this.stompClient.connected;
        if (connected) {
            this.subscribeToTopic(topic, callback);
            return;
        }

        this.stompClient.connect({},(): any => {
            this.subscribeToTopic(topic, callback)
        });
    }

    private subscribeToTopic(topic: string, callback : any): void {
        this.stompClient.subscribe(topic,(): any => {
            callback();
        });
    }
}