export class User
{
    uuid: string;
    name: string;
    email: string;
    avatar: string;
    status: string;
    sessionUuid: string

    constructor(){}
}
