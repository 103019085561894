import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(public authService: AuthService){
  }

  canActivate(){
      if (this.authService.isUser() ||this.authService.isCommercial() || this.authService.isAgent())
        return true
      else
        return false;
  }

}
