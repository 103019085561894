import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'translateMonth'})
export class TranslateMonthPipe implements PipeTransform {
  transform(value: string): string {
    const monthTranslations = {
      'January': 'Janvier',
      'February': 'Février',
      'March': 'Mars',
      'April': 'Avril',
      'May': 'Mai',
      'June': 'Juin',
      'July': 'Juillet',
      'August': 'Août',
      'September': 'Septembre',
      'October': 'Octobre',
      'November': 'Novembre',
      'December': 'Décembre'
    };
    return monthTranslations[value];
  }
}