import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { NativeDateAdapter } from '@angular/material/core';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter implements NgxMatDateAdapter<Date> {
  
  constructor() {
    super('en-US'); // Specify the locale for your date adapter, e.g., 'en-US'
  }
  getHour(date: Date): number {
    return date.getHours();
  }
  getMinute(date: Date): number {
    return date.getMinutes();
  }
  getSecond(date: Date): number {
    return date.getSeconds();
  }
  setHour(date: Date, value: number): Date {
    date.setHours(value);
    return date;
  }
  setMinute(date: Date, value: number): Date {
    date.setMinutes(value);
    return date;
  }
  setSecond(date: Date, value: number): Date {
    date.setSeconds(value);
    return date;
  }
  isSameTime(date1: Date, date2: Date): boolean {
    return (
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes() &&
      date1.getSeconds() === date2.getSeconds()
    );
  }
  copyTime(sourceDate: Date, targetDate: Date): Date {
    targetDate.setHours(sourceDate.getHours());
    targetDate.setMinutes(sourceDate.getMinutes());
    targetDate.setSeconds(sourceDate.getSeconds());
    return targetDate;
  }
  compareDateWithTime(date1: Date, date2: Date): number {
    const date1WithTime = new Date(date1);
    const date2WithTime = new Date(date2);

    // Set the time components of both dates to 0
    date1WithTime.setHours(0, 0, 0, 0);
    date2WithTime.setHours(0, 0, 0, 0);

    if (date1WithTime < date2WithTime) {
      return -1;
    } else if (date1WithTime > date2WithTime) {
      return 1;
    } else {
      return 0;
    }
  }
  setTimeByDefaultValues(date: Date, defaultTime: number[]): Date {
    date.setHours(defaultTime[0] || 0);
    date.setMinutes(defaultTime[1] || 0);
    date.setSeconds(defaultTime[2] || 0);
    return date;
  }

  format(date: Date, displayFormat: any): string {
    // Implement your custom formatting logic here
    // Example: return the date in the format "DD/MM/YYYY HH:MM"
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  // Implement other methods of the NgxMatDateAdapter interface as needed
}

export function customDateAdapterFactory(): NgxMatDateAdapter<Date> {
  return new CustomDateAdapter();
}

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    timeInput: 'HH:mm', // Include hours and minutes format
  },
};

export const CUSTOM_DATE_ADAPTER_PROVIDER = {
  provide: NgxMatDateAdapter,
  useFactory: customDateAdapterFactory,
};

export const CUSTOM_DATE_FORMATS_PROVIDER = {
  provide: MAT_DATE_FORMATS,
  useValue: CUSTOM_DATE_FORMATS,
};