import {Injectable, OnInit} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {FuseNavigationItem} from '@fuse/components/navigation';
import {FuseMockApiService} from '@fuse/lib/mock-api';
import {
    compactNavigation, defaultCandidateNavigation, defaultEmployeeNavigation,
    defaultPartnerNavigation,
    futuristicNavigation,
    horizontalNavigation
} from 'app/mock-api/common/navigation/data';
import {TranslocoService} from "@ngneat/transloco";
import {UserService} from "../../../shared/models/auth/user/user.service";
import {AuthService} from "../../../core/auth/auth.service";
import {ModuleService} from "../../../shared/services/backend/module/module.service";

@Injectable({
    providedIn: 'root'
})

export class NavigationMockApi{
    private _navigation: FuseNavigationItem[] = [];
    private _defaultPartnerNavigation: FuseNavigationItem[] = defaultPartnerNavigation;
    private readonly _defaultEmployeeNavigation: FuseNavigationItem[] = defaultEmployeeNavigation;
    private readonly _defaultCandidateNavigation: FuseNavigationItem[] = defaultCandidateNavigation;



    /**
     * Constructor
     */
    constructor(private moduleService: ModuleService,private authService:AuthService,private _fuseMockApiService: FuseMockApiService, private _translocoService: TranslocoService
    ) {
        // Register Mock API handlers
        this.registerHandlers();

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                return [
                    200,
                    {
                        default: cloneDeep(this._navigation),
                    }
                ];
            });
    }
}
