import { ChangeDetectorRef, Component } from '@angular/core';
import { SpinnerService } from '../spinnerService/spinner.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {

  dots = Array(7).fill(0);
  rectangles = Array(8).fill(0);
  loading!: boolean;
  constructor(private loaderService: SpinnerService,private cdref: ChangeDetectorRef) {
    this.loaderService.isLoading.subscribe((load) => {
      this.loading = load;
    });

  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
 }

}
