import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusInvoice'
})
export class StatusInvoicePipe implements PipeTransform {

  transform(value: string): string {
      if (!value) return value;
      if (typeof value !== 'string') return "Error while Transforming String"
      switch (value) {
          case 'NON_PAYE':
              return 'Non payé'
          case 'PARTIELLEMENT_PAYE':
              return 'Partiellement payé'
          case 'PARTIELLEMENT_LIVRE':
              return 'Partiellement livré'
          case 'PAYE':
              return 'Payé'
          case 'SUR_PAYE':
              return 'Sur payé'
          case 'CREE':
              return 'Crée'
          case 'ANNULE':
              return 'Annulé'
          case 'VALIDE':
              return 'Validé'
          case 'LIVRE':
              return 'Livré'
          case 'NON_LIVRE':
              return 'Non livré'
          case 'RECEIVED':
              return 'Réceptionnée'
          case 'NOT_RECEIVED':
              return 'Non Réceptionnée'
          case 'PARTIAL_RECEIVED':
              return 'Partiellement réceptionnée'
          case 'NOT_APPROVED':
              return 'non approvée'
      }
  }

}
