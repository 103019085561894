import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import {NgToastModule} from "ng-angular-popup";
import {NgxColorsModule} from "ngx-colors";
import {LayoutModule} from "./layout/layout.module";
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS, CustomDateAdapter } from './custom-date-adapter';
import { StompService } from './shared/services/backend/web-socket/StompService';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {LanguageInterceptor} from "./shared/language.interceptor";
import { ChartsModule } from 'ng2-charts';

import {MatSelectCountryLangToken, MatSelectCountryModule} from "@angular-material-extensions/select-country";
import {lang} from "moment";


const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash : true
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core module of your application
        CoreModule,
        NgToastModule ,
        // Layout module of your application
        LayoutModule,
        NgxColorsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        ChartsModule,
        MatSelectCountryModule.forRoot('fr'),

    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useExisting: LOCALE_ID  }, // Specify the locale for your date adapter, e.g., 'en-US'
        { provide: NgxMatDateAdapter, useClass: CustomDateAdapter },
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true
        },
        StompService,
        { provide: MatSelectCountryLangToken, useValue: 'fr' },
    ]
})
export class AppModule
{
}
