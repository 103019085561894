import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayNameSingleLetter'
})
export class DayNameSingleLetterPipe implements PipeTransform {
    transform(dayName: string): string {
        const dayEnglishNames = [
            'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'
        ];
        const dayFrenchNames = [
            'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'
        ];

        const index = dayEnglishNames.indexOf(dayName);
        if (index !== -1) {
            return dayFrenchNames[index].charAt(0).toUpperCase();
        }

        return '';
  }

}
