import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, catchError, map, Observable, ReplaySubject, tap, throwError} from 'rxjs';
import {User} from 'app/shared/models/auth/user/user.types';
import {PartnerService} from "../../../services/backend/partner/partner.service";
import {SirhService} from "../../../services/backend/collaborator/sirh.service";
import {RecrutementService} from 'app/shared/services/backend/recrutement/recrutement.service';
import {TokenClaims} from "../token-claims";
import {environment} from "../../../../../environments/environment";
import { ViewOptions } from 'app/components/admin/view-options';
import { DmsService } from 'app/shared/services/backend/Dms/dms.service';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    public _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    private _userSetting: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _userSystem: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _partnerRawData: ReplaySubject<User> = new ReplaySubject<User>(1);
    baseUrl = environment.authUrl
    partnerUrl = environment.partnersUrl
    sirhUrl = environment.sirhUrl;
    public avatar = new BehaviorSubject<string | null>('');
    public email = new BehaviorSubject<string | null>('');
    public name = new BehaviorSubject<string | null>('');
    avatar$ = this.avatar.asObservable();
    email$ = this.email.asObservable();
    name$ = this.name.asObservable();
    photoProfile: string;

    /**
     * Constructor
     */
    constructor(  private http: HttpClient) {

    }

    getPartnerPhoto(logo : string) {
        return `${this.partnerUrl}/partners/loadFile/` + logo
    }

    getUserByEmail(email: string) {
        const params = new HttpParams().set('email', email);
        return this.http.get(`${this.baseUrl}/users/getByEmail`, { params });
    }

    getSystemUser() {
        this.getUserByEmail("system@system.wind").subscribe({
            next:(response)=>{
                this.systemUser$ = response;
            }}
        )
    }
    set systemUser$(body: any) {
        this._userSystem.next(body)
    }

    get systemUser$(): Observable<any> {
        return this._userSystem.asObservable();
    }


    addRole(payload: any) {
        return this.http.post<any>(`${this.baseUrl}/roles`, payload).pipe(
            map((response) => {
                return response;
            }),
            catchError((error)=>{
                return throwError(error);
            })
        )
    }

    getAllRoles(uuid : any) {
        return this.http.get<any>(`${this.baseUrl}/roles/${uuid}`);
    }
    getallRoles() {
        return this.http.get<any>(`${this.baseUrl}/roles/ALL`);
    }

    getAllUsers(uuid : any) {
        return this.http.get<any>(`${this.baseUrl}/users/${uuid}`);
    }

    getAllUsersPerPage(options: ViewOptions, partnerUuid): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("partnerUuid", partnerUuid);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/users/getUsersPerPage`, { params: queryParams });

    }

    affectUser(payload: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/users/affect`, payload);
    }

    getConnectedUserRole(uuid : any) {
        return this.http.get<any>(`${this.baseUrl}/roles/user/${uuid}`);
    }

    saveSelectedModulesForRole(payload : any) {
        return this.http.post<any>(`${this.baseUrl}/acl/saveSelectedOptions`, payload);
    }

    updateSelectedModulesForRole(payload : any) {
        return this.http.post<any>(`${this.baseUrl}/acl/updateSelectedOptions`, payload).pipe(
            map((response) => {
              return response;
            })
          );
    }

    getUserByUUID(uuid: any) {
        return this.http.get<any>(`${this.baseUrl}/users/${uuid}`);
    }

    saveModuleAcls(payload) : Observable<any> {

        return this.http.post<any>(`${this.baseUrl}/acl/addModuleAclsForUser`, payload).pipe(
            map((response) => {
              return response;
            })
          );
    }

    getMenuAclByMenu(name : string) : Observable<any> {

        return this.http.get<any>(`${this.baseUrl}/acl/getMenuAclByMenu/${name}`).pipe(map(response => {
            return  response;
        }));
    }

    getAllPartnerModuleAcls(uuid : any) {
        uuid = uuid.replace(/"/g, '');
        return this.http.get<any>(`${this.baseUrl}/acl/getAllModuleAclsByUser/${uuid}`);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    getavatar(): Observable<any> {
        return this.avatar.asObservable();
    }

    sendavatar(avatar: string) {
        this.avatar.next(avatar);
    }

    sendEmail(email: string) {
        this.email.next(email);
    }
    sendName(name: string) {
        this.name.next(name);
    }
    clearAvatarCache(): void {
        this.avatar.next(null);
    }
    set userSetting$(body: any) {
        this._userSetting.next(body)
    }

    get userSetting$(): Observable<any> {
        return this._userSetting.asObservable();
    }

    get partnerRawData$(): Observable<any> {
        return this._partnerRawData.asObservable();
    }

    destroy() {
        this._user = new ReplaySubject<User>(1);

    }
    addRolewithPermissions(payload: any) {
        return this.http.post<any>(`${this.baseUrl}/roles/addnewRoleWithSelectedOptions`, payload).pipe(
            map((response) => {
                return response;
            })
        )
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Update the user
     *
     * @param user
     */
    updatePassword(payload, uuid){
        return this.http.put<boolean>(`${this.baseUrl}/users/update-password/${uuid}`,payload);
    }

}
