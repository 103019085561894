import { Injectable } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';
import { BehaviorSubject } from 'rxjs';
import { SpinnerStatus } from '../enum/SpinnerStatus';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public isLoading = new BehaviorSubject(false);
  constructor(private toastService:NgToastService) { }
  setLoading(message:string,status:SpinnerStatus){
    this.isLoading.subscribe((value)=>{
      if(value){
          setTimeout(() => {
              this.isLoading.next(false);
              if(status===SpinnerStatus.SUCCESS){
                this.toastService.success({detail:message})
              }else{
                this.toastService.error({detail:message})
              }
          }, 1500);
    }})
  }
  protected readonly SpinnerStatus=SpinnerStatus
}