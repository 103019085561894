<div  class="spinner-overlay" *ngIf="loading">
  <div class="spinner-container">
    <div class="spinner">
      <img src="/assets/imgs/kounouz-travel.svg" alt="Your SVG Image" class="center-image">
      <div class="dot-container">
        <div *ngFor="let i of dots" class="dot"></div>
      </div>
      <div class="rect-container">
        <div *ngFor="let i of rectangles" class="rect"></div>
      </div>
      <div class="arc"></div>
    </div>
    <div class="arc-container">
      <div class="arc-section arc-continuous"></div>
      <div class="arc-section arc-intermittent"></div>
      <div class="arc-section arc-dotted"></div>
      <div class="arc-section arc-dotted-extra"></div>
    </div>
  </div>
</div>