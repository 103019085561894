import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {catchError, map, timeout} from "rxjs/operators";
import {FournisseurResult} from "../../../models/crm/fournisseur/fournisseur-result";
import {CommercialModel} from "../../../models/stock/commercial-model";
import moment from "moment";
import {FactureModel} from "../../../models/builling/FactureModel";

@Injectable({
  providedIn: 'root'
})
export class CommercialService {

  constructor(private http: HttpClient) { }
    commercialResultList: Array<CommercialModel>;
    baseUrl = environment.commercialUrl;




    filterCommercials(options: any,nomCommercial?: string, searchFields?:any):Observable<any>{
        let params = new HttpParams();
        if (nomCommercial != null) {
            params = params.set('name', nomCommercial);
        }
        if (searchFields.startDate != null) {
            params = params.append("startDate", moment(searchFields.startDate).format('YYYY/MM/DD'))
        }
        if (searchFields.endDate != null) {
            params = params.append("endDate", moment(searchFields.endDate).format('YYYY/MM/DD'))
        }

        params = params.set('pageSize', options.pageSize);
        params = params.set('pageNum', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this.http.get<CommercialModel[]>(`${this.baseUrl}/commercial/filterCommercials`, {params: params})
    }

  getAllCommercial(options: any):Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("page", options.pageNum);
    queryParams = queryParams.append("size", options.pageSize);
    if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
    if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
    return this.http.get<any>(`${this.baseUrl}/commercial/perPage/`, {params: queryParams});
  }


  addCommercial(payload) {
    return this.http.post(`${this.baseUrl}/commercial/create`, payload).pipe(
        timeout(60000),
        catchError((e, c) => {
          return [];
        }),
        map((response: any) => {

          return response;
        })
    );
  }

  addSale(payload) {
    return this.http.post(`${this.baseUrl}/commercial/create-sale-commercial
`, payload).pipe(
        map((response: any) => {

          return response;
        })
    )
  }


    getAllcommercials(){
        return this.http.get<Array<any>>(`${this.baseUrl}/commercial/getAll`).pipe(map(response => {
            return this.commercialResultList = response;
        }));
    }

    getAllRevenues(commercialUuid: string, pageNum: number, pageSize: number, sortField: string, sortDirection: string, startDate?: string, endDate?: string): Observable<any> {
        let params = new HttpParams()
            .set('pageNum', pageNum.toString())
            .set('pageSize', pageSize.toString())
            .set('sortField', sortField)
            .set('sortDirection', sortDirection);

        if (startDate) {
            params = params.set('startDate', startDate);
        }

        if (endDate) {
            params = params.set('endDate', endDate);
        }

        return this.http.get<any>(`${this.baseUrl}/revenue/getAllRevenues/${commercialUuid}`, { params });
    }

    getCommercialUuid(email: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/commercial/getCommercial/uuid`, { params: new HttpParams().set('email', email) });
    }

    getCommercialByUuid(uuid: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/commercial/get-commercial-uuid/{uuid}`, { params: new HttpParams().set('uuid', uuid) });
    }
}
