
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private dataSource: BehaviorSubject<string> = new BehaviorSubject<string>('Initial Value');
  data: Observable<any> = this.dataSource.asObservable();

  private dataSource2: BehaviorSubject<string> = new BehaviorSubject<string>('Initial Value');
  data2: Observable<any> = this.dataSource2.asObservable();




  private dataSource4: BehaviorSubject<string> = new BehaviorSubject<string>('Initial Value');
  data4: Observable<any> = this.dataSource4.asObservable();

  private userAvatarDataSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userAvatar: Observable<any> = this.userAvatarDataSource.asObservable();

  constructor() { }

  sendData(data: any) {
    this.dataSource.next(data);
  }

  sendData2(data: any) {
    this.dataSource2.next(data);
  }

  sendData4(data: any) {
    this.dataSource4.next(data);
  }
  sendUserAvatar(data: any) {
    this.userAvatarDataSource.next(data);
  }
  destroy(){
      this.dataSource = new BehaviorSubject<string>('Initial Value')
      this.dataSource2= new BehaviorSubject<string>('Initial Value')
      this.dataSource4 = new BehaviorSubject<string>('Initial Value')
      this.userAvatarDataSource = new BehaviorSubject<string>('Initial Value')

  }
}

