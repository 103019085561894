import { ContratModel } from '../../../models/sirh/contrat/contrat-model';

import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
} from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from '../../../../core/local-storage/local-storage.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { CollaboratorModel } from '../../../models/sirh/collaborator-model';
import { CollaboratorUpdate } from '../../../../components/admin/collaborator/update-colloborator/collaborator-update';
import { ViewOptions } from '../../../../components/admin/view-options';
import { CraUpdateDto } from '../../../models/cra/cra-update-dto';
import { DefaultActivities } from '../../../models/cra/DefaultActivities';
import { SaleDto } from 'app/components/admin/dash-crm/models/SaleDto';
import { ApiPaths } from 'app/shared/constants/apiPaths';
import {CallCenterAgentDto} from "../../../../components/admin/dash-crm/models/CallCenterAgentDto";
import {AgentSearchDto} from "../../../../components/admin/dash-crm/models/AgentSearchDto";
import moment from "moment/moment";
import {DATE_FORMATS_V2} from "../../../constants/date";

@Injectable({
    providedIn: 'root',
})
export class SirhService {
    baseUrl = environment.sirhUrl;

    //OldCollaborator Observable  for update
    private CollaboratorId = new BehaviorSubject<string | null>('');
    collaboratorId$ = this.CollaboratorId.asObservable();
    uuid: string;

    sendCollaboratorId(collaboratorId: string) {
        this.CollaboratorId.next(collaboratorId);
    }

    uploadFileCollab(payload: any) {
        return this.http.post(
            `${this.baseUrl}/collaborator/createCsvFile`,
            payload
        );
    }

    getCollaboratorID(): Observable<any> {
        return this.CollaboratorId.asObservable();
    }

    //end declaration  OldCollaborator Observablefor update
    constructor(
        private http: HttpClient,
        private localSt: LocalStorageService
    ) { }

    //Changed to getAllActiveCollab methode
    getAllCollaborators(): Observable<any> {
        return this.http
            .get<Array<CollaboratorModel>>(`${this.baseUrl}/collaborators`)
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getAllCollaboratorsNotAffected(): Observable<any> {
        return this.http
            .get<Array<CollaboratorModel>>(
                `${this.baseUrl}/collaborators/listNotAffected`
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getAllActiveCollaborators(): Observable<any> {
        return this.http
            .get<Array<CollaboratorModel>>(
                `${this.baseUrl}/collaborators/active`
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }
    checkBankAccount(banckAccount: string, collaborator?: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.append("banckAccount", banckAccount);
        if (collaborator) {
            params = params.append("collaborator", collaborator);

        }

        return this.http.get<boolean>(`${this.baseUrl}/bank-checks/checkBanckAccount`, { params });
    }
    getAllInactiveCollaborators(): Observable<any> {
        return this.http
            .get<Array<CollaboratorModel>>(
                `${this.baseUrl}/collaborators/inactive`
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    previewDoc(documentIdentifier: any): Observable<any> {
        return this.http
            .get(`${this.baseUrl}/documents/v2/${documentIdentifier}`, {
                responseType: 'arraybuffer',
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    filterCollaborator(options: any) {
        let params = new HttpParams();
        params = params.append('pageSize', options.pageSize);
        params = params.append('pageNum', options.pageNum);
               if (options.uuid!==null)
                   params = params.append('uuid', options.uuid);
               if (options.isdeleted!==null)
                   params = params.append('isdeleted', options.isdeleted);
               if (options.matricule)
                   params = params.append('matricule', options.matricule);
               if (options.department)
                   params = params.append('department', options.department);
               if (options.sortField)
                   params = params.append('sortField', options.sortField);
              if (options.sortDirection)
                   params = params.append('sortDirection', options.sortDirection);
        return this.http
            .get<any>(`${this.baseUrl}/collaborators/filterCollaborator`, {
                params: params,
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getAllCollaboratorsByTenantId(): Observable<any> {
        return this.http.get(`${this.baseUrl}/collaborators/lists`);
    }

    getCollaboratorsList(): Observable<any> {
        return this.http.get(`${this.baseUrl}/collaborators/all`);
    }

    collaboratorsactive(): Observable<any> {
        return this.http.get(`${this.baseUrl}/collaboratorsactive`);
    }

    collaboratorsactives(): Observable<any> {
        return this.http.get(
            `${this.baseUrl}/collaborators/AllActivesCollaborators`
        );
    }

    getContractCollab(uuid: any): Observable<any> {
        return this.http.get(`${this.baseUrl}/contracts/collaborator/${uuid}`);
    }

    createCollaborator(payload: any) {
        return this.http.post(`${this.baseUrl}/collaborators/create`, payload);
    }

    createCollaboratorFromCsv(payload: any) {
        return this.http.post(
            `${this.baseUrl}/collaborators/createcsv`,
            payload
        );
    }

    affectManager(uuid: any, uuiddepartement: any) {
        return this.http.post<any>(
            `${this.baseUrl}/collaborators/affect-collaborator/${uuid}/${uuiddepartement}`,
            {}
        );
    }

    createCommercialCrm(commercial:SaleDto):Observable<SaleDto>{
        return this.http.post<SaleDto>(`${this.baseUrl}/collaborators/createSale`, commercial);
    }

    dropCollaborator(uuid: any) {
        return this.http.get(`${this.baseUrl}/collaborators/deleteid/${uuid}`);
    }

    activateCollaborator(uuid: any) {
        return this.http.get(`${this.baseUrl}/collaborators/activate/${uuid}`);
    }

    addpicture(formData: any, uuid: any) {
        return this.http
            .post(`${this.baseUrl}/collaborators/uploadFiles/${uuid}`, formData)
            .pipe(
                timeout(60000),
                catchError((e, c) => {
                    return []; // only for catch error to work, it requires a return of array, object..
                }),
                map((response: any) => {
                    return response;
                })
            );
    }

    checkcin(payload: any, tenantId: any) {
        return this.http
            .post(
                `${this.baseUrl}/collaborators/checkCardNumber/${payload}/${tenantId}`,
                payload
            )
            .pipe(
                timeout(60000),
                catchError((e, c) => {
                    return []; // only for catch error to work, it requires a return of array, object..
                }),
                map((response: any) => {
                    return response;
                })
            );
    }

    checkEmail(payload: any) {
        return this.http
            .post(
                `${this.baseUrl}/collaborators/checkEmail/${payload}`,
                payload
            )
            .pipe(
                timeout(60000),
                catchError((e, c) => {
                    return []; // only for catch error to work, it requires a return of array, object..
                }),
                map((response: any) => {
                    return response;
                })
            );
    }

    checkSocialSecurityNumber(payload: string, tenantId: number, collaborator?: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.append("socialSecurityNumber", payload);
        params = params.append("tenant_id", tenantId);
        if (collaborator) {
            params = params.append("collaborator", collaborator);
        }

        return this.http.get<boolean>(`${this.baseUrl}/collaborators/checkSocialSecurityNumber`, { params });
    }

    checkCollabFullName(firstName: string, lastName: string, collaborator?: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.append("firstname", firstName);
        params = params.append("lastname", lastName);
        if (collaborator) {
            params = params.append("collaborator", collaborator);
        }

        return this.http.get<boolean>(`${this.baseUrl}/${ApiPaths.CheckCollaboratorFullName}`, { params });
    }


    checkCNSSNumber(payload: string, tenantId: number) {
        return this.http
            .post(
                `${this.baseUrl}/collaborators/checkCnssNumber/${payload}/${tenantId}`,
                payload
            )
            .pipe(
                timeout(60000),
                catchError((e, c) => {
                    return [];
                }),
                map((response) => {
                    return response;
                })
            );
    }



    getCollaboratorByUuid(uuid): Observable<any> {
        return this.http.get<CollaboratorModel>(
            `${this.baseUrl}/collaborators/${uuid}`
        );
    }

    getCollaborator(uuid: string) {
        return this.http.get<any>(`${this.baseUrl}/collaborators/${uuid}`);
    }

    updateCollaborator(collaborator: CollaboratorUpdate) {
        return this.http.patch(
            `${this.baseUrl}/collaborators/update/`,
            collaborator
        );
    }

    updateCollaboratorimgs(f: File, uuid, type) {
        const formData = new FormData();
        formData.append('personalPicture', f);
        return this.http.post<any>(
            `${this.baseUrl}/collaborators/updatePicture/${uuid}/${type}`,
            formData,
            {
                headers: { enctype: 'multipart/form-data' },
            }
        );
    }

    getActiveContractByCollaboratorId(collabUuid) {
        return this.http.get(
            `${this.baseUrl}/contracts/getActiveContractByCollaboratorId/${collabUuid}`
        );
    }

    getCollaboratorInDepartment(uuidDepartment: any): Observable<any> {
        return this.http.get<CollaboratorModel>(
            `${this.baseUrl}/collaborators/listCollaboratorsByDepartment/${uuidDepartment}`
        );
    }

    getActiveContractWithNewSalaire() {
        return this.http.get<ContratModel[]>(
            `${this.baseUrl}/contracts/getActiveContractByCollaborator`
        );
    }

    getAllAgentsRecActive() {
        return this.http.get(`${this.baseUrl}/collaborators/sourcingList`);
    }

    getAllValidCollaboratorsBydepots(depotUuid: any): Observable<any> {
        return this.http
            .get<Array<CollaboratorModel>>(
                `${this.baseUrl}/collaborators/getAllCollaboratorsAffecByDepots/${depotUuid}`
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getValidCollaborators(): Observable<any> {
        return this.http.get(`${this.baseUrl}/collaborators/getFiltredCollabs`);
    }

    getAllCollabActiveBypage(pagNum: any, pageSize: any) {
        return this.http
            .get<any>(
                `${this.baseUrl}/collaborators/active/${pagNum + 1}/${pageSize}`
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getCollabActiveBypage(options: any): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('pageSize', options.pageSize);
        queryParams = queryParams.append('pageNum', options.pageNum);
        if (options.sortField)
            queryParams = queryParams.append('sortField', options.sortField);
        if (options.sortDirection)
            queryParams = queryParams.append(
                'sortDirection',
                options.sortDirection
            );
        return this.http.get(`${this.baseUrl}/collaborators/actives/`, {
            params: queryParams,
        });
    }

    resentpwd(email: string, id: string) {
        return this.http.get<any>(`${this.baseUrl}/resentEmail`, {
            params: { email: email, id: id },
        });
    }

    getCollabName(uuid: string) {
        return this.http.get<any>(
            `${this.baseUrl}/collaborators/getName/${uuid}`
        );
    }

    getAllCollaboratorsByStatus(status: boolean): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('status', status);
        return this.http.get(`${this.baseUrl}/collaboratorsbystatus`, {
            params: queryParams,
        });
    }

    countEndContract() {
        return this.http.get<any>(`${this.baseUrl}/contracts/countEndContract`);
    }

    setUuid(uuid: string): void {
        this.uuid = uuid;
    }

    getUuid(): string {
        return this.uuid;
    }

    getAllActiveEmails() {
        return this.http.get<any>(`${this.baseUrl}/collaborators/emailsList`);
    }

    public getAllCraForEmployes(): Observable<any> {
        return this.http.get(`${this.baseUrl}/cra/collaborator`);
    }

    public createNewCra(craType: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/cra/create`, craType);
    }

    public getALLCraByPage(options: ViewOptions): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('size', options.pageSize);
        queryParams = queryParams.append('page', options.pageNum);
        if (options.sortDirection)
            queryParams = queryParams.append(
                'sortDirection',
                options.sortDirection
            );
        if (options.sortField)
            queryParams = queryParams.append('sortField', options.sortField);
        return this.http.get(`${this.baseUrl}/cra/filter`, {
            params: queryParams,
        });
    }

    public getALLCraByPageWithFilter(
        options: ViewOptions,
        fieldset: {
            month: string;
            year: number;
            weekOfMonth: number;
            status: string;
        }
    ): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('pageSize', options.pageSize);
        queryParams = queryParams.append('pageNum', options.pageNum);
        if (options.sortDirection)
            queryParams = queryParams.append(
                'sortDirection',
                options.sortDirection
            );
        if (options.sortField)
            queryParams = queryParams.append('sortField', options.sortField);
        queryParams = queryParams.appendAll(fieldset);
        return this.http.get(`${this.baseUrl}/cra/filter`, {
            params: queryParams,
        });
    }

    public findCraById(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/cra/` + id);
    }

    public fillWeeklyCra(id: number, cra: CraUpdateDto): Observable<any> {
        return this.http.put(`${this.baseUrl}/cra/update/` + id, cra);
    }

    public getALLCraByPageWithFilterForAdmin(
        options: ViewOptions,
        fieldset: {
            month: string;
            year: number;
            collaborator: string;
        }
    ): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('pageSize', options.pageSize);
        queryParams = queryParams.append('pageNum', options.pageNum);
        if (options.sortDirection)
            queryParams = queryParams.append(
                'sortDirection',
                options.sortDirection
            );
        if (options.sortField)
            queryParams = queryParams.append('sortField', options.sortField);
        queryParams = queryParams.appendAll(fieldset);
        return this.http.get(`${this.baseUrl}/cra/activityreports`, {
            params: queryParams,
        });
    }

    public getALLCraByPageWithoutFilterForAdmin(
        options: ViewOptions
    ): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('pageSize', options.pageSize);
        queryParams = queryParams.append('pageNum', options.pageNum);
        if (options.sortDirection)
            queryParams = queryParams.append(
                'sortDirection',
                options.sortDirection
            );
        if (options.sortField)
            queryParams = queryParams.append('sortField', options.sortField);
        return this.http.get(`${this.baseUrl}/cra/activityreports`, {
            params: queryParams,
        });
    }

    public findForCollabByDate(
        uuid: string,
        month: number,
        year: number
    ): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('uuid', uuid);
        queryParams = queryParams.append('month', month);
        queryParams = queryParams.append('year', year);
        return this.http.get(
            `${this.baseUrl}/cra/activityreports/collaborator`,
            { params: queryParams }
        );
    }

    public generateCraReport(craModel: any): Observable<any> {
        return this.http
            .post(`${this.baseUrl}/cra/generate/cra`, craModel, {
                responseType: 'blob',
            })
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error)
                )
            );
    }

    public enableCra(id: any): Observable<any> {
        return this.http.post<string>(
            `${this.baseUrl}/cra/activityreports/enable`,
            id
        );
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof Blob) {
            // Convert Blob error response to text
            return new Observable((observer) => {
                const reader = new FileReader();
                reader.onload = () => {
                    try {
                        const errorText = reader.result as string;
                        const errorMsg = JSON.parse(errorText);
                        observer.error(errorMsg);
                    } catch (e) {
                        observer.error(
                            'An error occurred while parsing the error response.'
                        );
                    }
                };
                reader.onerror = () => {
                    observer.error(
                        'An error occurred while reading the error response.'
                    );
                };
                reader.readAsText(error.error);
            });
        } else {
            return throwError('An unknown error occurred.');
        }
    }

    public clientName(uuid: any, year: number, month: number): Observable<any> {
        return this.http.get(
            `${this.baseUrl}/cra/activityreports/client/${uuid}/${year}/${month}`
        );
    }

    public validateCra(id: any): Observable<any> {
        return this.http.put(
            `${this.baseUrl}/cra/activityreports/validate/${id}`,
            null
        );
    }

    public validateCraMonthly(craModel: any): Observable<any> {
        return this.http.post(
            `${this.baseUrl}/cra/activityreports/cra/validate/monthly`,
            craModel
        );
    }
    public validateCraMonthlywithoutCustomer(craModel: any): Observable<any> {
        return this.http.post(
            `${this.baseUrl}/cra/validate/withoutclient`,
            craModel
        );
    }
    public filterValidatedCra(
        options: ViewOptions,
        fieldset: {
            clientuuid: string;
            collabuuid: string;
            date: string;
        }
    ): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('pageSize', options.pageSize);
        queryParams = queryParams.append('pageNum', options.pageNum);
        if (options.sortDirection)
            queryParams = queryParams.append(
                'sortDirection',
                options.sortDirection
            );
        if (options.sortField)
            queryParams = queryParams.append('sortField', options.sortField);
        if (fieldset != null) {
            if (fieldset.collabuuid) {
                queryParams = queryParams.append(
                    'collabuuid',
                    fieldset.collabuuid
                );
            }
            if (fieldset.clientuuid) {
                queryParams = queryParams.append(
                    'clientuuid',
                    fieldset.clientuuid
                );
            }
            if (fieldset.date) {
                queryParams = queryParams.append('date', fieldset.date);
            }
        }
        return this.http.get(`${this.baseUrl}/cra/activityreports/cra/valide`, {
            params: queryParams,
        });
    }

    public exportCraFacture(craModel: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/cra/facture`, craModel, {
            responseType: 'blob',
        });
    }

    createDefaultActivity(defaultActivity: DefaultActivities): Observable<any> {
        return this.http.post<DefaultActivities>(
            `${this.baseUrl}/defaultactivities/add`,
            defaultActivity
        );
    }

    getAllDefaultActivitiesPageableble(options: any): Observable<any> {
        let queryParams = new HttpParams();
        const params = new HttpParams();
        queryParams = queryParams.append('pageSize', options.pageSize);
        queryParams = queryParams.append('pageIndex', options.pageIndex);
        if (options.sortField)
            queryParams = queryParams.append('sortField', options.sortField);
        if (options.sortDirection)
            queryParams = queryParams.append(
                'sortDirection',
                options.sortDirection
            );
        if (options.demoStatus !== undefined)
            queryParams = queryParams.append('demoStatus', options.demoStatus);
        return this.http.get(`${this.baseUrl}/defaultactivities/pageable`, {
            params: queryParams,
        });
    }

    deleteDefaultActivity(id: number): Observable<void> {
        const url = `${this.baseUrl}/defaultactivities/${id}`;
        return this.http.delete<void>(url);
    }

    getAllDefaultActivities(): Observable<any[]> {
        return this.http.get<any[]>(`${this.baseUrl}/defaultactivities`).pipe(
            map((response) => {
                return response;
            })
        );
    }

    editDefaultActivity(id: number, activity: string): Observable<any> {
        const url = `${this.baseUrl}/defaultactivities/${id}`;
        return this.http.put(url, { activity });
    }

    createAgent(agent:CallCenterAgentDto):Observable<SaleDto>{
        return this.http.post<SaleDto>(`${this.baseUrl}/collaborators/create-center-agent`, agent);
    }

    updateAgent(agent:CallCenterAgentDto):Observable<SaleDto>{
        return this.http.put<SaleDto>(`${this.baseUrl}/collaborators/update-center-agent`, agent);
    }

    getAgents(options: ViewOptions,agentData:AgentSearchDto) {
        let params = new HttpParams();
        if (agentData.name != null) {
            params = params.set('name', agentData.name);
        }
        if (agentData.tenantId != null) {
            params = params.append("tenantId", agentData.tenantId)
        }

        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this.http.get(`${this.baseUrl}/collaborators/get-all-agent-center`, { params });
    }
}
