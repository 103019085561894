<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<app-loading-spinner></app-loading-spinner>
<!-- Navigation -->
<div class="hidden sm:block md:block lg:block xl:block "   [ngClass]="{'w-0 hidden':_toggleNavigation===false,'w-80 visible block':_toggleNavigation===true}" >

     <fuse-vertical-navigation class="dark  print:hidden " [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'"
        [navigation]="navigation.default" [opened]="!isScreenSmall">

            <!-- Navigation header hook -->
            <ng-container fuseVerticalNavigationContentHeader  class="fuse-vertical-navigation-opened">
                <!-- Navigation toggle button -->
                <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                </button>
                <div class="flex items-center m-auto  pl-6 w-40 mb-3 ">


                <img  class="imageLogoSideBar" src="/assets/imgs/KounouzTravelLogo.svg">
                <div class="flex items-center ml-auto">
                    <notifications></notifications>
                </div>
                </div>
<!--                 User-->
                                <div class="flex flex-col items-center w-full p-4">
                                    <p></p>
                                    <div class="flex flex-col items-center justify-center w-full mt-6">
                                        <div
                                            class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                                            {{user?.name}}
                                        </div>
                                        <div
                                            class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                                            {{user?.email}}
                                        </div>
                                    </div>
                                </div>
            </ng-container>
            <!-- Navigation footer hook -->
            <ng-container fuseVerticalNavigationContentFooter>
                <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
                    <img class="imageLogo" src="/assets/imgs/LogoWindERP.svg">
                </div>
            </ng-container>

    </fuse-vertical-navigation>
</div>




<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 ">


    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')" *ngIf="_toggleNavigation===false">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center  ml-auto space-x-0.5 sm:space-x-2 change_with_in_responsive">

            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
             <languages></languages>
            <div [matMenuTriggerFor]="userActions"
                class=" mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden  text-md leading-normal font-medium text-secondary cursor-pointer">
                {{user?.name}}
            </div>
            <button mat-icon-button [matMenuTriggerFor]="userActions">
                <div class="relative  ">
                    <img class="inline-block h-[2.875rem] w-[2.875rem] rounded-full ring-2 ring-white dark:ring-gray-800"
                    [src]="'./assets/imgs/KounouzTravelLogo.svg'" alt="User avatar">

                </div>
            </button>
            <mat-menu [xPosition]="'before'" #userActions="matMenu">
                <button mat-menu-item>
                    <span class="flex flex-col leading-none">
                        <span>Connecté en tant que:</span>
                        <span class="mt-1.5 text-md font-bold">{{user.email}}</span>
                    </span>
                </button>
                <mat-divider class="my-2"></mat-divider>
                <button mat-menu-item (click)="signOut()">
                    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                    <span>se déconnecter</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto bg-card">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

</div>

