
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { catchError, Observable, of, ReplaySubject, Subject, switchMap, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/shared/models/auth/user/user.service';
import {environment} from 'environments/environment';
import {PartnerService} from 'app/shared/services/backend/partner/partner.service';
import {map, timeout} from "rxjs/operators";
import {JwtHelperService} from '@auth0/angular-jwt';
import {ActivatedRoute, Router} from "@angular/router";
import {TokenClaims} from "../../shared/models/auth/token-claims";
import { ViewOptions } from 'app/components/admin/view-options';
import moment from 'moment';
import { DATE_FORMATS_V2 } from 'app/shared/constants/date';
import { SaleSearchDto } from 'app/components/admin/dash-crm/models/SaleSearchDto';

@Injectable()
export class AuthService {

    private _authenticated: boolean = false;
    baseUrl = environment.authUrl;
    public role: string;
    private _tokenClaims: ReplaySubject<TokenClaims> = new ReplaySubject<TokenClaims>(1);

    public uuidPack: string;
    modulesSideBar: any;
    public uuid : string ;

    private checkSubject = new Subject<void>();
    check$ = this.checkSubject.asObservable();


    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // ----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set tokenClaims(value: TokenClaims) {
        this._tokenClaims.next(value);
    }
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    set refreshToken(refreshToken: string) {
        localStorage.setItem('refreshToken', refreshToken);
    }

    get refresh(): string
    {
        return localStorage.getItem('refresh') ?? '';
    }

    set refresh(refresh: string) {
        localStorage.setItem('refresh', refresh);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Forgot password
     *
     * @Request Body contient  email & url to redirect user toi page rest
     *
     */
    forgotPassword(email: string): Observable<any> {
        let RestPasswordDto = {
            email: email,
            url: window.location.origin + "/#/reset-password"
        }
        return this._httpClient.post(this.baseUrl + '/users/reset-password', RestPasswordDto, { responseType: 'text' });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string, activeToken: string): Observable<any> {
        let RestPasswordDto = {
            "password": password,
            "token": activeToken
        }
        return this._httpClient.post(this.baseUrl + '/users/change-password', RestPasswordDto);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string; rememberMe:boolean }): Observable<any> {
        // Throw error, if the user is already logged in
        if(!credentials.rememberMe)
        {credentials.rememberMe = false}
        if (this._authenticated) {
            catchError((error) => {
                return throwError(() => error('L’utilisateur est déjà connecté.'));
            })
        }
        return this._httpClient.post(`${this.baseUrl}/authenticate`, credentials, { observe: 'response' }).pipe(
            switchMap((response: any) => {
                    // Store the access token in the local storage
                    this.accessToken = response.body.access_token
                     this.refreshToken = response.body.refresh_token
                this.parseJWT()
                this.refresh = "0"
                // Set the authenticated flag to true
                    this._authenticated = true;
                    return of(response.body);
                }
            )
        );
    }
    verifyPassword(credentials: { username: string; password: string; rememberMe:boolean }): Observable<any>{
        return this._httpClient.post(`${this.baseUrl}/verifyPassword`, credentials, { observe: 'response' })
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>
                // Return false
                of(false)
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }
                // Set the authenticated flag to true
                this._authenticated = true;
                // Store the user on the user service
                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.clear()
        // window.location.reload()
        // Set the authenticated flag to false
        this._authenticated = false;
        // Return the observable
        return of(true);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }


    parseJWT() {
        let jwtHelper = new JwtHelperService();
        let objJWT = jwtHelper.decodeToken(this.accessToken);
        this.role = objJWT.authorities;
        this.uuidPack = objJWT.pack_id;
        this.modulesSideBar = objJWT.modules_sidebar
        this.uuid = objJWT.access_id;
        const payloadTokenClaims ={
            uuid:objJWT.access_id,
            role:objJWT.authorities,
        }
        this.tokenClaims= payloadTokenClaims
        localStorage.setItem('pack_id', objJWT.pack_id);
        localStorage.setItem('useruuid', objJWT.access_id);
        localStorage.setItem('role', objJWT.authorities);
        localStorage.setItem('hasCategory', objJWT.hasCategory);


    }


    isAdmin() {
        return this.role.indexOf('ROLE_ADMIN') >= 0;
    }

    isSystem() {
        return this.role.indexOf('ROLE_SYSTEM') >= 0;
    }

    isUser() {

        return this.role.indexOf('ROLE_USER') >= 0;
    }

    isCommercial() {
        return this.role.indexOf('ROLE_COMMERCIAL') >= 0 ;
    }
    isAgent() {
        return this.role.indexOf('ROLE_CENTER_AGENT') >= 0 ;
    }

    isCandidate() {
        return this.role.indexOf('ROLE_CANDIDATE') >= 0;
    }

    login(credentials: { username: string; password: string }): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}`, credentials)
    }

    checkMail(email: any) {
        return this._httpClient.get(`${this.baseUrl}/users/checkMail/${email}`)


    }
    destroy() {
        this._tokenClaims = new ReplaySubject<TokenClaims>(1);

    }

    getAllModuleAcls() {
        return this._httpClient.get(`${this.baseUrl}/acl`);
    }

    canAddCollaborator(): Observable<boolean> {
        return this._httpClient.get<any>(`${this.baseUrl}/payment/canAddCollaborator`);
    }
    userPaymentAssignment(collaboratorUUID: string): Observable<boolean> {
        const params = new HttpParams().set('collaboratorUUID', collaboratorUUID);
        return this._httpClient.put<any>(`${this.baseUrl}/payment/userPaymentAssignment`, null, { params: params });
    }

    createPayment(payment:any): Observable<any>{
        return this._httpClient.post<any>(`${this.baseUrl}/payment/create-payment`, payment)
    }

    createDemoPayment(payment:any): Observable<any>{
        return this._httpClient.post<any>(`${this.baseUrl}/payment/create-demo-payment`, payment)
    }

    getAllPaymentSystem(options:any): Observable<any>{
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.name) queryParams = queryParams.append("partner", options.name);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        if (options.start) { queryParams = queryParams.append('start', options.start); }
        if (options.end) { queryParams = queryParams.append('end', options.end); }
        return this._httpClient.get<any>(`${this.baseUrl}/payment/getAllPaymentSystem`, {params: queryParams})
    }

    activatePayment(orderId:string) {
        return this._httpClient.get<any>(`${this.baseUrl}/payment/activatePayment/${orderId}`);
    }


    getUsersFilterCRMPerPage(options: ViewOptions,salesData:SaleSearchDto) {
        let params = new HttpParams();
        if (salesData.name != null) {
            params = params.set('name', salesData.name);
        }
        if (salesData.typeCommercial != null) {
            params = params.set('typeCommercial', salesData.typeCommercial);
        }
        if (salesData.startDate != null) {
            params = params.append("startDate", moment(salesData.startDate).format(DATE_FORMATS_V2))
        }
        if (salesData.endDate != null) {
            params = params.append("endDate", moment(salesData.endDate).format(DATE_FORMATS_V2))
        }
        if (salesData.tenantId != null) {
            params = params.append("tenantId", salesData.tenantId)
        }

        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this._httpClient.get(`${this.baseUrl}/users/getUsersFilterCRMPerPage`, { params });
    }

    exportListSales(options: ViewOptions,salesData:SaleSearchDto){

        let params = new HttpParams();
        if (salesData.name != null) {
            params = params.set('name', salesData.name);
        }
        if (salesData.typeCommercial != null) {
            params = params.set('typeCommercial', salesData.typeCommercial);
        }
        if (salesData.startDate != null) {
            params = params.append("startDate", moment(salesData.startDate).format(DATE_FORMATS_V2))
        }
        if (salesData.endDate != null) {
            params = params.append("endDate", moment(salesData.endDate).format(DATE_FORMATS_V2))
        }

        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this._httpClient.get(`${this.baseUrl}/users/exportListSalesDto`, {
            params: params,
            responseType: 'arraybuffer'
        }) as Observable<ArrayBuffer>;
    }

    registerClickToPayOrder(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/click-to-pay/register-order`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    getClickToPayOrderStatus(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/click-to-pay/get-order-status`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    getPendingPartnerPayments(uuid) {
        return this._httpClient.get<any>(`${this.baseUrl}/payment/getPendingPartnerPayments/${uuid}`);
    }

    getLastPartnerPayment(uuid) {
        return this._httpClient.get<any>(`${this.baseUrl}/payment/getLastPartnerPayment/${uuid}`);
    }

    updatePaymentStatus(orderStatusPayload) {
        return this._httpClient.post(`${this.baseUrl}/payment/updatePaymentStatus`, orderStatusPayload).pipe(
            timeout(60000),
            catchError((e, c) => {
                //this.toastr.error(" Error!  ");
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    performCheckAction() {
        this.checkSubject.next();
    }

    getAllPayments(options: ViewOptions) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this._httpClient.get<any>(`${this.baseUrl}/payment/pageable`, {params: queryParams});
    }
    passwordFormatRegex() {
        return this._httpClient.get<any>(`${this.baseUrl}/users/passwordFormatRegex`);
    }

    renewPartnerPayment(partnerPaymentUuid: any) {
        const params = new HttpParams().set('partnerPaymentUUID', partnerPaymentUuid);
        return this._httpClient.put<any>(`${this.baseUrl}/payment/renewUserPayment`, null, { params: params });
      }

    logout(userUuid: string) {
        let userParam = new HttpParams();
        userParam = userParam.append("userUuid", userUuid);
        return this._httpClient.post<any>(`${this.baseUrl}/users/logout`,null, {params: userParam});
    }

    setAccountExpiredDate(userUuid){
        let userParam = new HttpParams();
        userParam = userParam.append("userUuid", userUuid);
        return this._httpClient.post(`${this.baseUrl}/users/expired-account`,null, {params: userParam}).pipe(
                map(response => {
                  return response;
        }));
    }

    connectSession(email: string): Observable<any> {
        return this._httpClient.post<any>(`${this.baseUrl}/connect`, email, { observe: 'response' }).pipe(
            switchMap((response: any) => {
                this.accessToken = response.body.access_token;
                this.refreshToken = response.body.refresh_token;
                this.parseJWT();
                this.refresh = "0";
                return of(response.body);
            })
        );
    }


    getAllSessionHistoriesPerPage(options: ViewOptions): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.sortField) {
            queryParams = queryParams.append("sortField", options.sortField);
        }
        if (options.sortDirection) {
            queryParams = queryParams.append("sortDirection", options.sortDirection);
        }
        return this._httpClient.get(`${this.baseUrl}/getSessionHistoriesPerPage`, { params: queryParams });
    }

    getCRMDotNetToken(){
        const token ='eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYW1pbmEuYmVudGFoZXIiLCJleHAiOjE3Mjg4MzI1NzcsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjUwMDAiLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo1MDAwIn0.S7Z7FCvkO6q0CsdrllkcUYwFhdlztA6QeFigVipm6m8'
        return token;
    }
}
