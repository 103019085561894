import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {ModuleElementIdDto} from "../../../models/module/ModuleElementIdDto";

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  updateFreePriceStockParams(payload: { uuid: string; hasFreePrice: any; authorityForFreePrice: string; }) {
    return this.http.put(`${this.baseUrl}/setting/updateFreePricesOptions`, payload).pipe(
        map((response: any) => {
            return response;
        })
    );
  }

    baseUrl = environment.moduleUrl;

    constructor(private http: HttpClient) {
    }

    updateModule(module: any) {
        return this.http.put(`${this.baseUrl}/modules/update`,module).pipe(map(response => {
            return response;
        }));
    }
    deleteModule(uuid: any) {
        return this.http.delete(`${this.baseUrl}/modules/delete/${uuid}`).pipe(map(response => {
            return response;
        }));
    }

    getAllModules(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getAllModules`).pipe(map(response => {
            return  response;
        }));
    }

    getAllParentModules(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/parent-modules`).pipe(map(response => {
            return  response;
        }));
    }

    getSideBarMenuElements(userUuid: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getSideBarMenuElements/${userUuid}`).pipe(map(response => {
            return  response;
        }));
    }

    getAllSubModulesByModule(id : number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/sub-modules/${id}`).pipe(map(response => {
            return  response;
        }));
    }

    getAllChildModules(parentModuleId: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/child-modules/${parentModuleId}`).pipe(map(response => {
            return  response;
        }));
    }

    getSelectedOptionsByRole(uuid: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getSelectedOptionsByRole/${uuid}`).pipe(map(response => {
            return  response;
        }));
    }

    getSelectedOptionsByUser(uuid: string): Observable<any> {
        return this.http.get<ModuleElementIdDto>(`${this.baseUrl}/modules/getSelectedOptionsByUser/${uuid}`).pipe(map(response => {
            return  response;
        }));
    }

    isUserPermitted(payload) : Observable<boolean>{
        return this.http.post<boolean>(`${this.baseUrl}/modules/isUserPermitted`, payload);
    }

    getModule(uuid: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/modules/${uuid}`);
    }
    getModuleById(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/modules/getById/${id}`);
    }

    getSubModule(uuid: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/sub-modules/get/${uuid}`);
    }


    getConfig(): Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/setting/getAllSetting`).pipe(map(response => {
            return response;
        }))
    }

    updateSkuSetting( data :any ): Observable<any>{
        return this.http.post<any>(`${this.baseUrl}/setting/updateSkuSetting` , data).pipe(map(response => {
            return response;
        }))
    }

    getConfigSku(): Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/setting/getSkuConfig`).pipe(map(response => {
            return response;
        }))
    }

    getConfigContract(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/setting/config/contract/getConfigContract`).pipe(map(response => {
            return  response;
        }))
    }
    updateConfig(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/config/contract/updateConfig`, payload)
      };
    getPackModulesSideBar(uuid:any): Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/packModuleSideBar/getByUuid/${uuid}`).pipe(map(response => {
            return response;
        }));
    }


    updateFacturationConfig(payload: any) {
        return this.http.put(`${this.baseUrl}/setting`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateGeneralFacturationParams(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/generalParams`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateFacturationOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/facturationOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateAdvanceUpdateOption(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/advanceOption`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updatePaymentOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/paymentOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateStockProductOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/productOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateStockPaymentOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/paymentStockOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    updatePrelevementOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/updatePrelevementOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateStockFacturationOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/stockFacturationOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateStockCancelDays(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/updateStockCancelDays`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateGeneralStockParams(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/updateGeneralStockParams`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    createFacturationConfig(payload: any) {
        return this.http.post(`${this.baseUrl}/setting`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getAllFacturationConfig(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/setting/`).pipe(map(response => {
            return  response;
        }));
    }

    createModule(payload: any) {
        return this.http.post(`${this.baseUrl}/modules/create`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    // getChildElements(payload) : Observable<any> {
    //     return this.http.get<any>(`${this.baseUrl}/modules/getChildElements/`, payload).pipe(map(response => {
    //         return  response;
    //     }));
    // }

    getChildElements(payload) : Observable<any> {
        let elementId = payload.elementId;
        return this.http.get<any>(`${this.baseUrl}/modules/getChildElements/${elementId}`).pipe(map(response => {
            return  response;
        }));
    }

    getTabsByUser(payload) : Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", payload.userUuid);
        queryParams = queryParams.append("elementId", payload.elementId);
        return this.http.get(`${this.baseUrl}/modules/getTabsByUser/`, {params: queryParams});
    }

    getButtonsOfPage(payload) : Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", payload.userUuid);
        queryParams = queryParams.append("elementId", payload.elementId);
        return this.http.get(`${this.baseUrl}/modules/getButtonsOfPage/`, {params: queryParams});
    }

    getAllModuleByPage(options: any):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/modules/getAllByPage/`, {params: queryParams});
    }

    getAllMenus(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getAllModulesByMenuType`).pipe(map(response => {
            return  response;
        }));
    }

    updateModelPaie(payload): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("modelPaie", payload);
        return this.http.post(`${this.baseUrl}/setting/updateModelePaie`, null, { params: queryParams });
    }



    updateGracePeriode(gracePeriod: number): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("gracePeriod", gracePeriod);
        return this.http.post(`${this.baseUrl}/setting/gracePeriod`, queryParams);
    }


    createPasswordFormatRegex(payload): Observable<any> {
        return this.http.post(`${this.baseUrl}/setting/createPasswordFormatRegex`, payload);
    }

    getSystemSettingById(systemSettingId:number): Observable<any> {
        return this.http.get(`${this.baseUrl}/setting/getSystemSettingById/${systemSettingId}`);
    }

    createModulesPartnerPrice(partnerModulePrice:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/modulesPrices/createModulesPartnerPrice`,partnerModulePrice);
    }
    getPartnerModulesPrice(userUuid:any): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", userUuid);
        return this.http.get(`${this.baseUrl}/modulesPrices/getPartnerModulesPrice`,{params:queryParams});
    }
    getPartnerListModulesPrice(userUuid:string): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", userUuid);
        return this.http.get(`${this.baseUrl}/modulesPrices/getPartnerListModulesPrice`,{params:queryParams});
    }
    deleteModulePartnerPrice(modulePriceId:string,userUuid:string): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", userUuid);
        queryParams = queryParams.append("modulePriceId", modulePriceId);
        return this.http.delete(`${this.baseUrl}/modulesPrices/deleteModulePartnerPrice`,{params:queryParams});
    }
    updateCaisseOfPartner(payload:any): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", payload.userUuid);
        queryParams = queryParams.append("caissePrice", payload.caissePrice);
        queryParams = queryParams.append("numberOfCaisse", payload.numberOfCaisse);
        return this.http.put(`${this.baseUrl}/modulesPrices/updateCaisseOfPartner`,null,{params:queryParams});
    }


    updateHsWaiterStatus(isWithServer: boolean): Observable<any> {
        const serverPresentString = isWithServer ? 'true' : 'false';
        return this.http.put<any>(`${this.baseUrl}/setting/updateHsWaiterStatus`, null, {
            params: new HttpParams().set('serverPresent', serverPresentString),
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(
            map(response => response),
            catchError(error => {
                console.error('Erreur lors de la mise à jour du statut :', error);
                return throwError(() => new Error('Erreur lors de la mise à jour du statut'));
            })
        );
    }

    getHasWaiterStatus(): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/setting/hasWaiter`);
      }


getCraStatus(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/setting/craMonthly`);
}

getCraAndActivityStatus(): Observable<any> {
    return  this.http.get<any>(`${this.baseUrl}/setting/cra/status`);
}
getPaydHolidaysStatus(): Observable<any> {
        return  this.http.get<any>(`${this.baseUrl}/setting/cra/holidays/status`);
}

updateCra( craMonthly: boolean ,multiTask: boolean, withLeaves:boolean ): Observable<any> {
    const body = {
        craMonthly: craMonthly,
        multiTask: multiTask,
        withLeaves: withLeaves,
    };
    return this.http.put<any>(`${this.baseUrl}/setting/update/cra`, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
    updatePayedHolidaysStatus(payedHolidays: boolean): Observable<any> {
        const params = new HttpParams().set('payedHolidaysStatus', payedHolidays.toString());

        return this.http.put<any>(`${this.baseUrl}/setting/cra/status/payed`, {}, {
            params: params,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }


    CraUpdateStatus(craStatus: boolean): Observable<any> {
    const craMonthly =craStatus ? 'true' : 'false';
    return this.http.put<any>(`${this.baseUrl}/setting/update/cra`, null, {
        params: new HttpParams().set('craMonthly', craMonthly),
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }).pipe(
        map(response => response),
        catchError(error => {
            return throwError(() => new Error('Erreur lors de la mise à jour du statut'));
        })
    );
}


      createModulePrices(payload): Observable<any> {

        return this.http.post(`${this.baseUrl}/modulesPrices/createModulePrices`, payload);
    }
    updateColumnName(payload){
        let params = new HttpParams()
        .set("oldColumnName", payload.oldColumnName)
        .set("newColumnName", payload.newColumnName);
        return this.http.put(`${this.baseUrl}/modulesPrices/updateColumnName`,null,{params:params});
    }
    getAllModulePrices(): Observable<any> {
        return this.http.get(`${this.baseUrl}/modulesPrices/getAllModulePrices`);
    }
    removeModulePrice(moduleId:number): Observable<any> {
        return this.http.delete(`${this.baseUrl}/modulesPrices/deleteModulePrice/${moduleId}`);
    }
    getCaissePriceById(systemCaisseId:number): Observable<any> {
        return this.http.get(`${this.baseUrl}/modulesPrices/getCaissePriceById/${systemCaisseId}`);
    }
    updateCaissePrice(caisse:any): Observable<any> {
        return this.http.put(`${this.baseUrl}/modulesPrices/updateCaissePrice`,caisse);
    }
}

