<div class="absolute inset-0 flex flex-col min-w-0 overflow-y-scroll bg-white">

    <!-- Main -->
    <mat-sidenav-container
        class="h-full" (backdropClick)="sidenav.close()">
        <!-- Side Menu -->
        <mat-sidenav #sidenav (keydown.escape)="sidenav.close()" class="w-60" disableClose>
            <ul class="flex flex-col">
                <li class="pt-5 px-7">
                    <a class="text-3xl navLabel" href="#accueil" aria-current="page">Accueil</a>
                    <hr class="w-[85%] border border-yellow-400 my-3 lg:hidden">
                </li>
                <li class="pt-5 px-7">
                    <a class="text-3xl navLabel" href="#service">Services</a>
                    <hr class="w-[85%] border border-yellow-400 my-3 lg:hidden">
                </li>
                <li class="pt-5 px-7">
                    <a class="text-3xl navLabel" href="#">Tarifs</a>
                    <hr class="w-[85%] border border-yellow-400 my-3 lg:hidden">
                </li>
                <li class="pt-5 px-7">
                    <a class="text-3xl navLabel" href="#contact">Contact</a>
                    <hr class="w-[85%] border border-yellow-400 my-3 lg:hidden">
                </li>
                <li class="pt-5 px-7">
                    <a>
                        <button [matMenuTriggerFor]="menu"
                                class="navLabel  text-[21px] inline-flex items-center pt-1">
                            FR
                            <mat-icon class="text-black text-[4px]" svgIcon="feather:chevron-down"></mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item>Item 1</button>
                            <button mat-menu-item>Item 2</button>
                        </mat-menu>
                    </a>
                </li>
            </ul>
        </mat-sidenav>

        <!-- CONTENT GOES HERE -->
        <mat-sidenav-content class="w-full" id="container">
            <header class="fixed z-10 w-full max-h-24 min-h-20"
                    [ngClass]="{'fixed-header': true, 'scrolled-header': isScrolled,'button-cnx':isScrolled}">
                <div class="flex justify-between items-center max-h-30 lg:hidden">
                    <button mat-button (click)="sidenav.open()" class="ml-5">
                        <mat-icon svgIcon="heroicons_outline:menu" class="text-white"></mat-icon>
                    </button>

                    <div class="max-h-30 min-h-20">
                        <div class="flex items-center w-70">
                            <img class="w-full max-h-30 h-20 min-h-20" src="../../../../assets/imgs/LogoWindERP.svg" alt="">
                        </div>
                    </div>

                    <button [matMenuTriggerFor]="menuCnx" class="mr-14">
                        <mat-icon svgIcon="heroicons_outline:user-circle" class="text-white"></mat-icon>

                    </button>
                    <mat-menu #menuCnx="matMenu">
                        <button mat-menu-item (click)="goToLoginPage()">Connexion</button>
                        <button mat-menu-item (click)="goToInscriptionPage()">Inscription</button>
                    </mat-menu>
                </div>

                <div class="hidden lg:flex lg:justify-around lg:mt-4">
                    <img class="w-30 -mt-6" src="../../../../assets/imgs/LogoWindERP.svg" alt="">
                    <ul class="flex my-5">
                        <li class="mx-6">
                            <a class="pt-6 text-xl navLabel lg:text-3xl lg:py-6 text-white  hover:text-yellow-400" (click)="goToHomePage()">Accueil</a>
                            <hr class="w-[85%] border border-yellow-400 m-auto lg:hidden">
                        </li>
                        <li class="mx-6">
                            <a class="text-xl navLabel lg:text-3xl lg:py-6 text-white  hover:text-yellow-400" >Services</a>
                            <hr class="w-[85%] border border-yellow-400 m-auto lg:hidden">
                        </li>
                        <li class="mx-6">
                            <a class="text-xl navLabel lg:text-3xl lg:py-6 text-white  hover:text-yellow-400" >Tarifs</a>
                            <hr class="w-[85%] border border-yellow-400 m-auto lg:hidden">
                        </li>
                        <li class="mx-6">
                            <a class="text-xl navLabel lg:text-3xl lg:py-6 text-white  hover:text-yellow-400" >Simulateur de paie</a>
                            <hr class="w-[85%] border border-yellow-400 m-auto lg:hidden">
                        </li>
                        <li class="mx-6">
                            <a class="text-xl navLabel lg:text-3xl lg:py-6 text-white  hover:text-yellow-400" >Contact</a>
                            <hr class="w-[85%] border border-yellow-400 m-auto lg:hidden">
                        </li>
                        <li class="hidden mx-6 lg:inline">
                            <a class="pt-5 lg:py-6">
                                <button type="button" (click)="goToLoginPage()"
                                        class="inline-flex items-center px-5 py-2 text-base font-bold text-blue-700 bg-yellow-400 border-2 border-yellow-400 rounded-full shadow-sm hover:shadow-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 button-cnx">
                                    Connexion
                                </button>
                            </a>
                        </li>
                        <li class="hidden mx-6 lg:inline">
                            <a class="pt-5 lg:py-6">
                                <button type="button" (click)="goToInscriptionPage()"
                                        class="inline-flex items-center px-5 py-2 text-base font-bold text-yellow-400 bg-transparent border-2 border-yellow-400 rounded-full shadow-sm hover:bg-white hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:border-gray-300">
                                    Inscription
                                </button>
                            </a>
                        </li>
                    </ul>
                </div>

            </header>


            <div class="homeimage" id="accueil">
                <div class="container h-screen relative">
                    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <div class="flex justify-center mt-home lg:grid lg:grid-cols-15">
                            <div class="mt-0 max-w-220 lg:col-start-4 lg:col-end-12">
                                <p class="mt-0 font font-extrabold text-center text-blue-700 text-6xl xs:text-6xl">
                                    Une démo vaut mieux qu'un long discours
                                </p>
                                <p class="mt-5 font-light text-center md:text-2xl xs:text-xl">
                                    Planifiez, facturez et suivez vos interventions au bureau et sur le terrain
                                    avec notre application web et mobile.
                                </p>
                            </div>

                        </div>
                        <div class="relative w-full flex items-center justify-center">
                            <div #imageContainer class="flex overflow-hidden space-x-4 mt-12 p-4">
                                <img *ngFor="let img of images" [src]="img" alt="" class="h-40 w-40 object-contain">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="px-20">
                <br>
                <div class="grid grid-cols-12 place-items-left">
                    <div class='col-span-12 m-1 space-x-10 gap-x-1 gap-y-4'>
                        <div class="grid items-start grid-cols-12 gap-8">
                            <div class="col-span-12 md:col-span-6">
                                <div class="col-span-12 mb-5 relative">
                                    <hr class="absolute border-4 border-yellow-400 w-160 top-4 left-0">
                                    <div class="sm:text-[50px] xs:text-[25px] font-extrabold text-left relative z-10 mt-6" id="service">
                                        Laissez-nous vous faire découvrir notre solution
                                    </div>
                                </div>
                                <p class="sm:text-[18px] xs:text-[14px] text-blue-700 mb-4">
                                    Renseignez le formulaire et nous vous contacterons pour vous aider à augmenter vos ventes, gagner de nouveaux contacts et automatiser votre business.
                                </p>
                                <br>
                                <p class="sm:text-[18px] xs:text-[14px] text-black">
                                    <span style="color: #008000; font-size: 18px;">&#10003;</span>
                                    Un outil flexible destiné à toutes les entreprises, permettant de partager des connaissances, expédier des projets et collaborer.
                                </p>
                                <br>
                                <p class="sm:text-[18px] xs:text-[14px] text-black">
                                    <span style="color: #008000; font-size: 18px;">&#10003;</span>
                                    Fonctionnalités d'entreprises pour gérer en toute sécurité l'accès et la sécurité des utilisateurs.
                                </p>
                                <br>
                                <p class="sm:text-[18px] xs:text-[14px] text-black">
                                    <span style="color: #008000; font-size: 18px;">&#10003;</span>
                                    Un support dédié pour travailler avec vous sur votre configuration et vous aider à construire le meilleur plan pour votre entreprise.
                                </p>
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <div class="md:flex-1">
                                    <div class="col-span-12 mb-5 relative">
                                        <hr class="absolute border-4 border-yellow-400 w-140 top-4 left-0">
                                        <div class="sm:text-[50px] xs:text-[25px] font-extrabold text-left relative z-10 mt-6" id="service">
                                            Demander une démo
                                        </div>
                                        <p class="text-[18px] text-left font-bold">Notre équipe est disponible pour échanger avec vous sur vos besoins.</p>

                                    </div>
                                    <fuse-card class="p-1 pl-5 pr-5 mt-2">
                                        <div class="flex inline-flex mx-auto mt-4">
                                            <div class="text-[50px] text-center font-bold">
                                                <div class="grid grid-cols-1 gap-4">
                                                    <div class="grid grid-cols-1 gap-4">
                                                        <div class="grid grid-cols-12 gap-4">
                                                            <div class="col-span-6">
                                                                <mat-form-field class="w-full form-name" appearance="outline">
                                                                    <mat-label>Nom</mat-label>
                                                                    <input matInput placeholder="Nom">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-span-6">
                                                                <mat-form-field class="w-full" appearance="outline">
                                                                    <mat-label>Prénom</mat-label>
                                                                    <input matInput placeholder="Prénom">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-span-12">
                                                                <mat-form-field class="w-full" appearance="outline">
                                                                    <mat-label>Email professionnel</mat-label>
                                                                    <input matInput placeholder="Email">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-span-12">
                                                                <mat-form-field class="w-full" appearance="outline">
                                                                    <mat-label>Numéro de téléphone</mat-label>
                                                                    <input matInput placeholder="Numéro de téléphone">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-span-12">
                                                                <mat-form-field class="w-full" appearance="outline">
                                                                    <mat-label>Société</mat-label>
                                                                    <input matInput placeholder="Société">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-span-12">
                                                                <mat-form-field class="w-full" appearance="outline">
                                                                    <mat-label>Fonction</mat-label>
                                                                    <input matInput placeholder="Fonction">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-span-12">
                                                                <mat-form-field class="w-full" appearance="outline">
                                                                    <mat-label>Pays</mat-label>
                                                                    <input matInput placeholder="Pays">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-span-12">
                                                                <mat-form-field class="w-full" appearance="outline">
                                                                    <mat-label>Message</mat-label>
                                                                    <textarea rows="5" matInput></textarea>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="text-left">
                                                        <mat-checkbox class="example-margin">Accepter les politiques de confidentialité
                                                            et les conditions générales d'utilisation
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="flex justify-start mb-10">
                                                    <button type="button"
                                                            class="inline-flex items-center px-12 py-4 text-2xl font-medium leading-4 text-yellow-400 bg-blue-700 border border-transparent rounded-full shadow-sm hover:shadow-amber-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                        Envoyer
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fuse-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bgfooter mt-4">
                <div class='grid grid-cols-12'>
                    <div class="col-span-12 mb-5 xs:mx-10 sm:mx-25 md:mx-40">
                    </div>
                    <div class="col-span-12 mb-5 xs:mx-10 sm:mx-25 md:mx-40">
                    </div>
                    <div class="col-span-6 flex items-center justify-center">
                        <div class="flex flex-col items-center justify-center xs:justify-start md:justify-center">
                            <img class="w-30 mx-auto" src="../../../../assets/imgs/LogoWindERP.svg" alt="Logo Wind ERP">
                            <div class="text-center md:text-left md:ml-4 mt-2">
                                <p class="text-white">Est une solution RH globale, adaptée aux PME</p>
                                <div class="flex items-center mt-15 md:ml-4">
                                    <a href="https://www.facebook.com/profile.php?id=61553994200442&locale=fr_FR" target="_blank">
                                        <img src="assets/landing/Suivez/facebook.png" class="w-10 mr-2" alt="Facebook">
                                    </a>
                                    <a href="https://instagram.com/wind.erp/" target="_blank">
                                        <img src="assets/landing/Suivez/instagram.png" class="w-10 mr-2" alt="Instagram">
                                    </a>
                                    <a href="https://www.linkedin.com/company/100367959/admin/page-posts/published/" target="_blank">
                                        <img src="assets/landing/Suivez/linkedin.png" class="w-10 mr-2" alt="LinkedIn">
                                    </a>
                                    <a href="https://www.twitter.com" target="_blank">
                                        <img src="assets/landing/Suivez/twitter.png" class="w-10 mr-2" alt="Twitter">
                                    </a>
                                </div>
                                <div class="text-left md:text-left md:ml-4 mt-2">
                                    <p class="text-white">Suivez-nous</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-1 flex items-center justify-center">
                        <div class="flex items-center justify-center xs:mx-5 md:mx-0">
                            <div class="flex items-center">
                                <div class="grid grid-flow-row gap-2">
                                    <div class="flex flex-col">
                                        <div class="flex items-center my-1 mt-4">
                                            <a class="ml-2 text-white hover:text-yellow-400" href="#accueil">Accueil</a>
                                        </div>
                                        <div class="flex items-center my-1 mt-4">
                                            <a class="ml-2 text-white hover:text-yellow-400" href="#service">Services</a>
                                        </div>
                                        <div class="flex items-center my-1 mt-4">
                                            <a class="ml-2 text-white hover:text-yellow-400" href="#tarif">Tarifs</a>
                                        </div>
                                        <div class="flex items-center my-1 mt-4">
                                            <a class="ml-2 text-white hover:text-yellow-400" href="#contact">Contact</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-span-4 flex items-center justify-center">
                        <div class="flex items-center justify-center xs:mx-5 md:mx-0">
                            <div class="flex items-center">
                                <div class="grid grid-flow-row gap-2">
                                    <div class="text-yellow-400">
                                        <p class="ml-auto">Coordonnées</p>
                                    </div>
                                    <div class="flex flex-col">
                                        <div class="flex items-center my-1 mt-10">
                                            <img src="assets/landing/Suivez/mail.png" class="w-6">
                                            <p class="ml-2 text-white">winderp@gmail.com</p>
                                        </div>
                                        <div class="flex items-center my-1 mt-6">
                                            <img src="assets/landing/Suivez/tel.png" class="w-6">
                                            <p class="ml-2 text-white">+216 00 000 000</p>
                                        </div>
                                        <div class="flex items-center my-1 mt-6">
                                            <img src="assets/landing/Suivez/map.png" class="w-6">
                                            <p class="ml-2 text-white">Croisement Birrjab, Immeuble Ben Nahia - Mahdia - Tunisie</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 mb-5 xs:mx-10 sm:mx-25 md:mx-40">
                    </div>
                    <div class="col-span-12 mb-5 xs:mx-10 sm:mx-25 md:mx-40">
                    </div>
                    <div class="col-span-12 mb-5 xs:mx-10 sm:mx-25 md:mx-40">
                    </div>
                    <div class="col-span-12 mb-5 xs:mx-10 sm:mx-25 md:mx-40">
                    </div>
                    <div class="col-span-6 flex items-center justify-center">
                        <div class="xs:mx-5 md:mx-0 text-white">
                            Copyright ⓒ Wind ERP, <span> {{ " "+ currentYear + " "}} </span> . All rights reserved.
                        </div>
                    </div>

                    <div class="col-span-6 flex items-center justify-center">
                        <div class="xs:mx-5 md:mx-0 text-white">
                            Website Terms  -  Privacy policy  -  Cookies Policy
                        </div>
                    </div>
                    <br>
                </div>
            </section>
        </mat-sidenav-content>
    </mat-sidenav-container>


