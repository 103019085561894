import { ViewportScroller } from '@angular/common';
import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent {
    @ViewChild('imageContainer', { static: true }) imageContainer!: ElementRef;
    currentYear = new Date().getFullYear();
    images: string[] = [
        '../../../../assets/imgs/gestEmp.png',
        '../../../../assets/imgs/gestStock.png',
        '../../../../assets/imgs/systemeErp.png',
        '../../../../assets/imgs/facturation.png'
    ];
  constructor(
      private _router: Router,private vps:ViewportScroller,
      private elementRef: ElementRef, private renderer: Renderer2
  )
  {
  }
  show = true;
  isScrolled = false;
  panelOpenState = false;
  visible = false;
   header = document.querySelector('header');
   sections = document.querySelectorAll('section');
   sectionOffsets = [];
  toggleCollapse(): void {
    this.visible = !this.visible;
  }
  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
  scroll = (event): void => {

     const container = document.getElementById('container');
     container.addEventListener('scroll', () => {

          if(container.scrollTop >0){
              this.isScrolled = true;
          }
          else{
              this.isScrolled = false;
          }
      });
  }

  goToLoginPage() {
      this._router.navigate(['sign-in']);
  }

  goToInscriptionPage() {
      this._router.navigate(['sign-up']);
  }
    goToHomePage(){
        this._router.navigate(['home']);
    }
}
