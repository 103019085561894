import {Component, HostListener, Inject, InjectionToken, LOCALE_ID} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter
} from "@angular/material-moment-adapter";
import { UserService } from './shared/models/auth/user/user.service';
import { AuthService } from './core/auth/auth.service';
import { User } from './shared/models/auth/user/user.types';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ],
})
export class AppComponent {

    constructor(private _adapter: DateAdapter<any>, private userService: UserService, private authService: AuthService, @Inject(MAT_DATE_LOCALE) private readonly _locale: string) {
        this._locale = 'fr';
        this._adapter.setLocale(this._locale);
    }

}
