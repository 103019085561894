import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { ModuleService } from "../../shared/services/backend/module/module.service";
import { AuthService } from "../auth/auth.service";
import { FuseNavigationItem } from "../../../@fuse/components/navigation";
import {
     customNavigation, defaultCandidateNavigation,
    defaultEmployeeNavigation,
    defaultPartnerNavigation,
    defaultPartnerNavigation2,
    defaultSystemNavigation,
} from "../../mock-api/common/navigation/data";
import { UserService } from '../../shared/models/auth/user/user.service';
import {ENTREPRISE_MA_PACK, FUTUR_SAT_CLIENT_PACK, ME_TOO_CLIENT_PACK} from "../../shared/constants/global";


@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _partnerNavigation: FuseNavigationItem[] = defaultPartnerNavigation;
    private _partnerNavigation2: FuseNavigationItem[] = defaultPartnerNavigation2;
    private _customNavigation :  FuseNavigationItem[] = customNavigation;
    private _employeeNavigation: FuseNavigationItem[] = defaultEmployeeNavigation;
    private _defaultCandidateNavigation: FuseNavigationItem[] = defaultCandidateNavigation;
    private _defaultSystemNavigation: FuseNavigationItem[] = defaultSystemNavigation;
    private customMenu: FuseNavigationItem[] = [];
    userModuleList: any[];
    userUuid: string;

    /**
     * Constructor
     */


    packUuid: string;
    hasCategory : any;
    settingPartner: any;
    constructor(private authService: AuthService, private moduleServie: ModuleService, private moduleService: ModuleService  ,private _httpClient: HttpClient, private userService : UserService)
    {

    }



    getSideBarMenuElements(navigation) {
        let userUuid = localStorage.getItem('useruuid');
        this.moduleService.getSideBarMenuElements(userUuid).subscribe({
            next: (res) => {
                this.customMenu = res;
                navigation.default = this.customMenu;
            }
        });
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {

        //Back : getRoleByUserUuid, getModuleAclsByRole, generic service for all components

        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {

                if (this.authService.role == "ROLE_SYSTEM") {
                    navigation.default = this._defaultSystemNavigation;
                }
                else {
                    this.displayCustomMenu(navigation);

                }

                this._navigation.next(navigation);


            })
        );
    }

    displayCustomMenu(navigation) {
        this.getSideBarMenuElements(navigation);
    }


    displayCustomMenu2(navigation) {
        this.authService.getAllModuleAcls().subscribe({
            next: (res: any[]) => {
                this.userModuleList = res;
                this.userModuleList.forEach(element => {
                    if (element.parentModuleACL == null) {
                        this.addModuleToMenu(element.module, null);
                    }
                });
                this.userModuleList.forEach(element => {
                    if (element.parentModuleACL != null) {
                        this.addModuleToMenu(element.module, null);
                    }
                });
            }
        });
    }

    addModuleToMenu(moduleId: any, parentId: number | null) {
        this.moduleService.getModule(moduleId).subscribe({
            next: (res: any) => {
                const menu = {
                    id: res.idMenu,
                    title: res.title,
                    type: res.type,
                    icon: res.icon,
                    link: res.link,
                    children: []
                };

                if (parentId === null) {
                    this.customMenu.push(menu);
                } else {
                    this.moduleService.getModuleById(res.parentModule).subscribe({
                        next: (parent: any) => {
                    const parentMenu = this.findMenuById(parent.idMenu);
                    if (parentMenu) {
                        parentMenu.children.push(menu);
                    }
                }
                });
                }

                if (res.childrenModules && res.childrenModules.length > 0) {
                    res.childrenModules.forEach(childId => {
                        this.addModuleToMenu(childId, res.idMenu);
                    });
                }
            }
        });
    }

    findMenuById(menuId: any): any | undefined {
        return this.customMenu.find(item => item.id === menuId);
    }

    displayOldConfigMenu(navigation) {

        this.packUuid = localStorage.getItem('pack_id');
          this.hasCategory = localStorage.getItem('hasCategory');

          if (this.authService.uuidPack != null && this.packUuid != ME_TOO_CLIENT_PACK && this.packUuid !== FUTUR_SAT_CLIENT_PACK && this.packUuid !== ENTREPRISE_MA_PACK) {
            navigation.default = this.authService.modulesSideBar
          }

          else if (this.packUuid == ME_TOO_CLIENT_PACK || this.packUuid == FUTUR_SAT_CLIENT_PACK || this.packUuid == ENTREPRISE_MA_PACK){
                navigation.default = this._customNavigation;
          }

          else if (this.authService.role == "ROLE_ADMIN" ){
            if (this.hasCategory == "false") {
                navigation.default = this._partnerNavigation2;
            }
            else {
                navigation.default = this._partnerNavigation;
            }

          }

          else{
            switch (this.authService.role) {
                // case "ROLE_ADMIN":
                //     navigation.default = this._partnerNavigation;
                //     break;
                case "ROLE_USER":
                    navigation.default = this._employeeNavigation;
                    break;
                case "ROLE_CANDIDATE":
                    navigation.default = this._defaultCandidateNavigation;
                    break;
                case "ROLE_SYSTEM":
                    navigation.default = this._defaultSystemNavigation;
                    break;
                default:
                    // Handle the default case if needed
                    break;
            }

          }

                this._navigation.next(navigation);

        }


    destroy() {
        this._navigation = new ReplaySubject<Navigation>(1);
    }


}
