import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';
import { UserGuard } from './core/auth/guards/user.guard';
import { CandidateGuard } from './core/auth/guards/candidate.guard';
import { SystemGuard } from './core/auth/guards/system.guard';
import { DemoComponent } from './components/landing/demo/demo.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'sign-in'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect-admin', pathMatch: 'full', redirectTo: 'admin/Entreprise'},
    {path: 'signed-in-redirect-employee', pathMatch: 'full', redirectTo: '/employee/profile'},
    {path: 'signed-in-redirect-candidate', pathMatch: 'full', redirectTo: 'candidate/profile'},
    {path: 'signed-in-redirect-system', pathMatch: 'full', redirectTo: 'system/Partenaires'},
    {path: 'signed-in-redirect-agent', pathMatch: 'full', redirectTo: 'admin/crm/kounouz-crm/sondage'},
    {path: 'signed-in-redirect-commercial', pathMatch: 'full', redirectTo: 'admin/crm/kounouz-crm/suivi-leads'},



    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [

            // Landing routes
            {
                path: '',
                component: LayoutComponent,
                loadChildren: () => import('app/components/landing/home/home.module').then(m => m.LandingHomeModule)
            },
            {
                path: 'demo',
                component: DemoComponent
            },
            {
                path: 'payment',
                loadChildren: () => import('app/components/auth/payment/payment.module').then(m => m.PaymentModule)
            },

            {
                path: 'confirmation-required',
                loadChildren: () => import('app/components/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/components/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/components/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/components/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/components/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/components/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            },
            {
                path: 'auth',
                loadChildren: () => import('app/components/auth/email-confirmation/email-confirmation.module').then(m => m.EmailConfirmationModule)
            }

        ]
    },
    // Auth routes for authenticated users
    {
        path: 'log',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('app/components/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
            },
            {
                path: 'unlock-session',
                loadChildren: () => import('app/components/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)
            },
            {
                path: 'block-session',
                loadChildren: () => import('app/components/auth/block-session/block-session.module').then(m => m.AuthBlockSessiontModule)
            }
        ]
    },
    // Routes
    {
        canMatch: [AuthGuard],
        path: '',
        children: [
            {path: 'employee',canActivate: [UserGuard], loadChildren: () => import('app/components/Employe/employe.module').then(m => m.EmployeModule)},
            {path: 'admin', loadChildren: () => import('app/components/admin/admin.module').then(m => m.AdminModule)},
            {path: 'system',canActivate: [SystemGuard], loadChildren: () => import('app/components/system/system.module').then(m => m.SystemModule)},
            {path: 'candidate',canActivate: [CandidateGuard], loadChildren: () => import('app/components/candidate/candidat.module').then(m => m.CandidatModule)},
            {path: 'change-password', loadChildren: () => import('app/components/auth/edit-password/edit-password.module').then(m => m.EditPasswordModule)}
        ],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        }
    },


];
