import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayNameThreeLetters'
})
export class DayNameThreeLettersPipe implements PipeTransform {

    transform(dayName: string): string {
        const dayEnglishNames = [
            'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'
        ];
        const dayFrenchNames = [
            'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'
        ];

        const index = dayEnglishNames.indexOf(dayName);
        if (index !== -1) {
            return dayFrenchNames[index].substring(0, 3).toUpperCase();
  }

        return '';
    }
}
